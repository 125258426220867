import React, {useEffect, useState} from 'react';
import styles from '../Messages/index.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {buildQueryString} from "../../CMS/Darts/DartsGames/helpers/buildQueryString";
import moment from "moment";
import LoggingHeader from "../components/LoggingHeader/LoggingHeader";
import LoggingFilter from "../components/LoggingFilter/LoggingFilter";
import {Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import AppPagination from "../../../components/Pagination/AppPagination";
import {
    changePageSize, deleteConnection,
    getConnections,
    setPageNum
} from "../../../store/logging/loggingConectionsSlice";
import LoggingConfirmModal from "../Exceptions/components/LoggingConfirmModal/LoggingConfirmModal";


const Connections = () => {
    const dispatch = useAppDispatch()
    const {pageNum, pageSize, totalPages, connections} = useAppSelector(state => state.loggingConnectionsSlice)
    const [startDate, setStartDate] = useState<string | Date>(new Date());
    const [endDate, setEndDate] = useState<string | Date>(new Date());
    const [search, setSearch] = useState("");
    const [deletingRecord, setDeletingRecord] = useState<string | number>("");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    useEffect(() => {
        const query = buildQueryString({pageNum, pageSize})
        dispatch(getConnections(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onChangePageSize = (size: number) => {
        dispatch(changePageSize(size))
        const query = buildQueryString({pageNum, pageSize: size, search})
        dispatch(getConnections(query))
    }

    const onChangePageNum = (pageNum: number) => {
        dispatch(setPageNum(pageNum))
        const query = buildQueryString({pageNum, pageSize, search})
        dispatch(getConnections(query))
    }

    const onSearch = (search: string) => {
        setSearch(search)
        const query = buildQueryString({pageNum: 1, pageSize, search})
        dispatch(getConnections(query))
        dispatch(setPageNum(1))
    }

    const onChangeStartDate = (date: string) => {
        const from = moment(date).utc().format()
        const to = moment(endDate).utc().format()
        const query = buildQueryString({pageNum, pageSize, search, from, to})
        setStartDate(date)
        if (!from.includes("Invalid") && !to.includes("Invalid")) {
            dispatch(getConnections(query))
        }
    }

    const onChangeEndDate = (date: string) => {
        const from = moment(startDate).utc().format()
        const to = moment(date).utc().format()
        const query = buildQueryString({pageNum, pageSize, search, from, to})
        setEndDate(date)
        if (!from.includes("invalid") && !to.includes("invalid")) {
            dispatch(getConnections(query))
        }
    }
    const onDeleteMessage = () => {
        dispatch(deleteConnection(deletingRecord))
        setDeletingRecord("")
        setIsShowConfirmModal(false)
    }

    const showConfirmModal = (recordId: string | number) => {
        setDeletingRecord(recordId)
        setIsShowConfirmModal(true)
    }
    const closeConfirmModal = () => {
        setDeletingRecord("")
        setIsShowConfirmModal(false)
    }
    return (
        <div className={styles.page}>
            <LoggingHeader text={"Connections"}/>
            {isShowConfirmModal && <LoggingConfirmModal
                onDelete={onDeleteMessage}
                onClose={closeConfirmModal}
                text={`Are you sure you want to delete record with id ${deletingRecord}`}
            />}
            <div className={styles.container}>
                <LoggingFilter
                    search={search}
                    setSearch={onSearch}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={onChangeStartDate}
                    setEndDate={onChangeEndDate}
                />
            </div>
            <div className={styles.tableInner}>
                <Table celled inverted>

                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell textAlign={'center'}>Id</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>Time</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>User</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>Client</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>IP</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>Message</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}>Status</TableHeaderCell>
                            <TableHeaderCell textAlign={'center'}></TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {connections.map(connection => {
                            return (
                                <TableRow>
                                    <TableCell textAlign='center'>{connection.id}</TableCell>
                                    <TableCell
                                        textAlign='center'
                                    >
                                        {moment(connection.timeStart).local().format("YYYY-DD-MM HH:mm:ss")}
                                    </TableCell>
                                    <TableCell textAlign='center'>{connection.user}</TableCell>
                                    <TableCell textAlign='center'>{connection.client}</TableCell>
                                    <TableCell textAlign='center'>{connection.addressIP}</TableCell>
                                    <TableCell textAlign='center'>{connection.message}</TableCell>
                                    <TableCell textAlign='center'>{connection.status}</TableCell>
                                    <TableCell textAlign='center'>
                                        <button
                                            onClick={() => showConfirmModal(connection.id)}
                                            className={styles.controlBtn}>
                                            <Icon color={"red"} name={"trash alternate"}/>
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <div className={styles.pagination}>
                    <AppPagination
                        isInvertColor={true}
                        pageSize={pageSize}
                        onChangePageSize={onChangePageSize}
                        activePage={pageNum}
                        totalPages={totalPages}
                        onChangePager={onChangePageNum}
                    />
                </div>
            </div>
        </div>
    );
};

export default Connections;
