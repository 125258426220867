import React, {Fragment, useCallback, useEffect, useRef, useState,} from "react";
import {ConfirmationModal} from "../../../components/ConfirmationModal";
import moment from "moment";
import {GameStatus, GameStatuses} from "../../../enums/gameStatus";
import {Button, Grid} from "semantic-ui-react";
import classnames from "classnames";
import {useHistory, useParams} from "react-router-dom";
import EndGameModal from "../../../components/EndGameModal/EndGameModal";
import AlertModal from "../../../components/DartsGameAlertModal/AlertModal";
import WalkoverModal from "../components/WalkoverModal/WalkoverModal";
import Dartboard from "../components/Dartbord/dartboard_lib";
import {WarningModal} from "../components/WarningModal/WarningModal";
import {ActionsContainer} from "../../MatchContainer/components/ActionsContainer/ActionsContainer";
import {ScoreBoard} from "../components/ScoreBoard/ScoreBoard";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    addThrow,
    getInitScoreForCurrentGame,
    setDartsGameStatus,
    undoThrow
} from "../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {IGameIdDatePayload, IMatchStatusPayload, IThrowPayload} from "../../../store/dartsGame/currentGame/types";
import {GameTypes, Score, SportType} from "../../../enums/gameEvents";
import {createActiveGameAfterRefreshPage} from "./helpers/createActiveGameBeforeRefreshPage";
import "./styles.scss";
import _ from "lodash";


const DartBoard = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {
        currentGameStatus,
        isUndoThrowAllowed,
        isThrowAllowed,
        currentGameInitData,
        currentPlayerId,
    } = useAppSelector((state) => state.currentDartsGame);
    const {game, gameClassicStatus, isReadonly} = useAppSelector((state) => state.gameHandler)
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [gameStatusMessage, setGameStatusMessage] = useState("");
    const [gameStatusForSchedule, setNewGameStatusForSchedule] = useState(gameClassicStatus);
    const [isOpenEndGameModal, setIsOpenEndGameModal] = useState(false);
    const [isActionButtonClicked, setActionButtonClicked] = useState(false);
    const defaultSizeOfDartboard = window.innerHeight * 0.9;
    const [alertForWaitResponse, setAlertForWaitResponse] = useState(false);
    const [size, setSize] = useState<{ width: Number; height: number }>({
        width: defaultSizeOfDartboard,
        height: defaultSizeOfDartboard,
    });
    const pathName = history.location.pathname;

    const isDartsAdc = pathName.includes("darts-adc");

    const {gameId} = useParams<{ gameId: string }>();

    const dartboardRef = useRef<any>(null);

    const showAlertModal = () => {
        if (!isThrowAllowed) {
            setAlertForWaitResponse(true)
        }
        if (isThrowAllowed) {
            setAlertForWaitResponse(false)
        }
    }
    const throttledAddThrowInfo = _.throttle((lastShot) => {
        addThrowInfo(lastShot);
    }, 500);

    const handleThrow = (lastShot: IThrowPayload, isAllowed: boolean, showAlertModal: () => void) => {
        if (!currentPlayerId) {
            alert("Please, select player");
            return;
        }
        if (!isAllowed) {
            showAlertModal();
            return;
        }
        throttledAddThrowInfo(lastShot);
    };

    const onThrow = useCallback(
        ({detail: score}: { detail: Score }) => {
            if (currentGameStatus === GameStatuses.PreEnd) {
                alert("Game over Use the 'Undo' or 'EndGame' button");
                return;
            }
            const lastShot: IThrowPayload = {
                gameData: {
                    gameId: Number(gameId),
                    playerId: currentPlayerId!,
                    score: score.score,
                    date: moment().utc().toDate(),
                    bed: score.bed,
                    ring: score.ring,
                },
                gameType: isDartsAdc ? GameTypes.ADC : GameTypes.CLASSIC
            };
            handleThrow(lastShot, isThrowAllowed, showAlertModal);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentPlayerId, showAlertModal, isThrowAllowed, currentGameStatus]
    );

    useEffect(() => {
        getInitInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game]);

    const getInitInfo = () => {
        if (game) {
            dispatch(getInitScoreForCurrentGame({
                gameId: +gameId,
                gameType: isDartsAdc ? GameTypes.ADC : GameTypes.CLASSIC
            }));
        } else {
            createActiveGameAfterRefreshPage(+gameId, dispatch);
        }
    }
    const isDartsBoardDisabled = false;

    useEffect(() => {
        // https://www.npmjs.com/package/dartboard
        if (dartboardRef.current?.id) {
            dartboardRef.current.innerHTML = "";
            window.addEventListener("resize", handleWindowResize);
            const dartboard = new Dartboard(`#${dartboardRef.current.id}`);
            dartboard.render();
        }

        return () => {
            if (dartboardRef.current?.id) {
                window.removeEventListener("resize", handleWindowResize);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game, size]);

    useEffect(() => {
        if (!dartboardRef.current) {
            return;
        }
        dartboardRef.current.addEventListener("throw", onThrow);
        return () =>
            dartboardRef.current &&
            // eslint-disable-next-line react-hooks/exhaustive-deps
            dartboardRef.current.removeEventListener("throw", onThrow);
    }, [dartboardRef, onThrow, game]);

    useEffect(() => {
        if (isThrowAllowed) {
            setAlertForWaitResponse(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleThrow]);

    const handleWindowResize = () => {
        const width = window.innerHeight * 0.9;
        const height = width;

        setSize({width, height});

        if (dartboardRef.current) {
            dartboardRef.current.innerHTML = "";
            new Dartboard(`#${dartboardRef.current.id}`).render();
        }
    };


    const addThrowInfo = (payload: IThrowPayload) => {
        dispatch(addThrow(payload));
    };

    const onClickActionButtonDartboard = (
        newStatus: GameStatus,
        gameStatus: GameStatuses,
        message: string
    ) => {
        setGameStatusMessage(message);
        setNewGameStatusForSchedule(gameStatus);
        setActionButtonClicked(true);
    };

    const setDartsMatchStatus = (payload: IMatchStatusPayload) => {
        dispatch(setDartsGameStatus(payload));
    };

    const onConfirmActionModal = () => {
        if (
            gameStatusForSchedule === gameClassicStatus
        ) {
            setActionButtonClicked(false);
            return;
        }

        const status = gameStatusMessage;
        const matchStatus: IMatchStatusPayload = {
            gameData: {
                gameId: Number(gameId),
                status,
                date: moment().utc().toDate(),
            },
            status: gameStatusForSchedule,
            gameType: isDartsAdc ? GameTypes.ADC : GameTypes.CLASSIC,
            history,
            playerId: null
        };
        setDartsMatchStatus(matchStatus);
        setActionButtonClicked(false);
    };

    const handleUndo = (isAllowed: boolean) => {
        if (isAllowed) {
            const undoPayload: IGameIdDatePayload = {
                gameData: {
                    gameId: +gameId,
                    date: moment().utc().toDate(),
                },
                gameType: isDartsAdc ? GameTypes.ADC : GameTypes.CLASSIC
            };
            dispatch(undoThrow(undoPayload));
        } else {
            alert("Please wait for a response from the server");
        }
    };

    const handleBounce = () => {
        const score = {
            score: 0,
            bed: "B0",
            ring: "BounceOut",
            date: moment().utc().toDate(),
            isDoubleAttempt: false,
            scoreBeforeShot: 0,
        };
        onThrow({detail: score});
    };

    return (
        <Fragment>
            {game && (
                <Grid className="darts" centered>
                    <AlertModal open={alertForWaitResponse} text={'Please await response...'}/>
                    {(
                        <Grid.Column computer={8} tablet={13}>
                            <div
                                className="darts__container"
                                style={{justifyContent: "center"}}
                            >
                                {(
                                    <Fragment>
                                        <div className="undo-button-container">
                                            <Button
                                                disabled={currentPlayerId === null && currentGameStatus !== GameStatuses.PreEnd}
                                                className="undo-button"
                                                color="red"
                                                onClick={() => handleUndo(isUndoThrowAllowed)}
                                            >
                                                Undo
                                            </Button>
                                        </div>
                                        <div
                                            ref={dartboardRef}
                                            id="dartboard"
                                            className={classnames("darts__container__board", {
                                                "darts__container__board--disabled":
                                                isDartsBoardDisabled,
                                            })}
                                            style={{
                                                width: `${size.width}px`,
                                                height: `${size.height}px`,
                                            }}
                                        />
                                        <div className="bounce_out">
                                            <Button
                                                disabled={!isUndoThrowAllowed}
                                                onClick={handleBounce}
                                                color="blue">
                                                Bounce Out
                                            </Button>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </Grid.Column>
                    )}
                    <Grid.Column computer={8} stretched tablet={13}>
                        <Grid padded>
                            <Grid.Row className="h-65">
                                <div className="darts__details__scoreboard">
                                    <ScoreBoard
                                        currentPlayerId={currentPlayerId!}
                                        initData={currentGameInitData}
                                    />
                                </div>
                            </Grid.Row>
                            <Grid.Row stretched className="h-35">
                                <div className="darts__details__actions">
                                    <ActionsContainer
                                        setIsOpenEndGameModal={setIsOpenEndGameModal}
                                        isReadonly={isReadonly}
                                        sportType={SportType.Darts}
                                        onClickActionButton={onClickActionButtonDartboard}
                                    />
                                </div>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            )}

            {isOpenEndGameModal && (
                <EndGameModal
                    isOpen={isOpenEndGameModal}
                    onCloseModal={setIsOpenEndGameModal}
                />
            )}

            <WarningModal
                isOpen={isConfirmModalOpen}
                message="Please, select player"
                onClick={() => setIsConfirmModalOpen(false)}
            />
            <ConfirmationModal
                open={
                    isActionButtonClicked && gameStatusForSchedule !== GameStatuses.Walkover
                }
                content={`Confirm game to be ${gameStatusMessage}?`}
                onConfirm={onConfirmActionModal}
                onDecline={() => setActionButtonClicked(false)}
            />
            <WalkoverModal
                open={
                    isActionButtonClicked &&
                    gameStatusForSchedule === GameStatuses.Walkover
                }
                gameId={gameId}
                onDecline={() => setActionButtonClicked(false)}
            />
        </Fragment>
    );
};


export default DartBoard;
DartBoard.displayName = "DartBoard";
